import {useEffect, useState} from 'react';
import './itemCard.css';
import NoContentImage from '../../assets/Images/NoContent.png';
import Visible from '../../assets/Icons/Visible.svg';
import Invisible from '../../assets/Icons/Invisible.svg';
import LocationIcon from './../../assets/Icons/LocationIcon.svg';
import COD from '../../assets/Icons/COD.svg';
import {ReactComponent as StarFilled} from './../../assets/Icons/StarFilled.svg';
import {Link} from 'react-router-dom';
import getImage from '../../util/getImage';
import useGlobalState from '../../util/useGlobalState';
import ItemService from '../../services/item';
import useErrorState from '../../util/reducers/errorContext';
import {SNACKBAR_BUTTON_TYPES} from '../../assets/Data/LBSEnum';
import RatingFiller from '../ratingFiller/ratingFiller';
import {CircularProgress} from '@material-ui/core';

export default function ItemCard({
    item,
    favourited,
    isYourShed = false,
    updateIsPausedItem = () => {},
}) {
    const [itemImage, setItemImage] = useState('');
    const [itemSuburb, setItemSuburb] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const {errorDispatch} = useErrorState();

    const {state} = useGlobalState();
    // const {user} = state;
    const itemService = new ItemService();

    useEffect(() => {
        if (item?.images?.length > 0) {
            setItemImage(item.images[0]?.imageKey);
        }
        if (item?.address?.suburb) {
            setItemSuburb(item.address?.suburb);
        }
    }, [item]);

    const isPausedApply = async itemId => {
        const newItemDetails = {
            isPaused: !item.isPaused,
        };
        try {
            setIsLoading(true);
            const data = await itemService.updateIsPausedListingItem(
                itemId,
                newItemDetails,
            );
            if (!data) throw Error;
            updateIsPausedItem(itemId, data.isPaused);
            //    history.push(`/item/${params.itemId}`);
        } catch (error) {
            errorDispatch({
                type: 'openSnackBar',
                data: {
                    message:
                        'Failed to update the item. Please check your details and try again later.',
                    btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                    btnFunc: () => {
                        errorDispatch({type: 'closeSnackBar'});
                    },
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='ItemCard'>
            <div className='product-list'>
                <Link
                    // to={user ? `/item/${item.id}` : '/search'}
                    to={`/item/${item.id}`}
                    style={{
                        position: 'relative',
                        // cursor: user ? 'pointer' : 'not-allowed',
                        cursor: 'pointer',
                    }}
                >
                    <img
                        src={itemImage ? getImage(itemImage) : NoContentImage}
                        alt={item.title}
                        className='PreviewImage'
                    />
                    {favourited && (
                        <div className='favouriteStar'>
                            <StarFilled />
                        </div>
                    )}

                    
                    {isYourShed && item.isPaused && (
                        <>
                            <div className='overlay-pause'>Paused Listing
                            </div>
                        </>
                    )}
                    {isYourShed && isLoading && (
                        <div className='overlay-pause'>
                            {' '}
                            <CircularProgress color={'inherit'} />
                        </div>
                    )}
                </Link>
                {isYourShed && (
                            <div className='pause-icon'>
                                <img
                                    onClick={() => isPausedApply(item.id)}
                                    src={item.isPaused ? Invisible : Visible }
                                    alt={'pause'}
                                    title={
                                        item.isPaused
                                            ? 'Continue Listing'
                                            : 'Pause Listing'
                                    }
                                    // className='pause-icon'
                                />
                            </div>
                        )}
                <div className='ItemDetailsContainer'>
                    <div className='ItemNameText'>{item.title}</div>
                    <div className='price-rating'>
                        <div className='ItemPriceText'>
                            {item.price ? `$${item.price}` : 'Free'}
                        </div>
                        <div className='RatingSection'>
                            {item.rating ? (
                                <>
                                    <span className='ItemRateText'>
                                        {item.rating}/5
                                    </span>
                                    <div
                                        className='RatingStars'
                                        style={{marginLeft: 0}}
                                    >
                                        <RatingFiller rating={item.rating} />
                                    </div>
                                </>
                            ) : (
                                <div className='not-rating-yet'>
                                    No ratings yet
                                </div>
                            )}
                        </div>
                    </div>

                    {/* <div className='StatusLocationSection'>
                        <div className='ItemCardIconContainer'>
                            <img
                                src={DeliveryIcon}
                                alt=''
                                className='ItemCardIcon'
                            />
                        </div>
                        {item.deliveryOption !== 'NONE'
                            ? 'Available'
                            : 'Unavailable'}
                    </div> */}
                    <div className='StatusLocationSection'>
                        <div className='ItemCardIconContainer'>
                            <img
                                src={LocationIcon}
                                alt={itemSuburb}
                                className='ItemCardIcon'
                            />
                        </div>
                        <div
                            style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {itemSuburb}
                        </div>
                    </div>
                    {/* <div className='StatusLocationSection'>
                        <div className='ItemCardIconContainer'>
                            <img
                                src={COD}
                                alt={itemSuburb}
                                className='ItemCardIcon'
                            />
                        </div>
                        <div
                            style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {item?.isCOD ? "Available" : "Unavailable" }
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
