import React, {useEffect, useState} from 'react';
import './yourshed.css';
import {Link} from 'react-router-dom';
import PageWrapper from '../../components/pageWrapper/pageWrapper';
import UserShedNav from '../../components/UserShedNav/UserShedNav';
import ItemCard from '../../components/itemCard/itemCard';
import {isMobile} from 'react-device-detect';
import useGlobalState from '../../util/useGlobalState';
import Instance from '../../util/axios';
import {CircularProgress} from '@material-ui/core';

export default function Yourshed() {
    const {state} = useGlobalState();
    const {user} = state;
    const [accountContent, setAccountContent] = useState('Your Shed');
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getUserItems(user.id);
    }, [user]);

    const getUserItems = async userId => {
        const {data} = await Instance.get(`/users/${userId}/items`);
        if (data) {
            setItems(data);
            setLoading(false);
        }
    };

    const updateIsPausedItem=(itemId, value)=>{
            setItems((prevItems) => 
                prevItems.map((item) =>
                  item.id === itemId ? { ...item, isPaused : value } : item
                )
              )
    }
    return (
        <PageWrapper>
            <div className='UserShedWrapper'>
                {!isMobile && (
                    <UserShedNav
                        setAccountContent={setAccountContent}
                        accountContent={accountContent}
                    />
                )}
                <div className='UserShed__MainContent'>
                    <div className='container-fluid'>
                        <div className='UserShed__Title'>{accountContent}</div>

                        {loading ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center ',
                                    alignItems: 'center',
                                    height: '80%',
                                }}
                            >
                                <CircularProgress color={'secondary'} />
                            </div>
                        ) : (
                            <>
                                <div className='ItemCardSectionyourshed'>
                                    {items.length > 0 &&
                                        items?.map((item, i) => (
                                            <ItemCard
                                                item={item}
                                                userId={user.id}
                                                key={i}
                                                isYourShed={true}
                                                updateIsPausedItem={updateIsPausedItem}
                                            />
                                        ))}
                                    <div
                                        className='ItemCard'
                                        style={{justifyContent: 'flex-start'}}
                                    >
                                        <Link
                                            className='addItemBox'
                                            to='/postitem'
                                        >
                                            +
                                        </Link>
                                        <Link
                                            className='addItemButton'
                                            to='/postitem'
                                        >
                                            Add New Item
                                        </Link>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
}
