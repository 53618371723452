import ValidationTextInput from '../FormComponents/ValidationTextInput';
import LBSSwitch from '../LBSSwitch/LBSSwitch';
import {Fade} from '@material-ui/core';

export default function EditPriceDetails({state, dispatch, Errors, setErrors}) {
    const {
        isEditItemDiscount,
        editItemPrice,
        editItemDiscount,
        isEditItemFree,
        isEditCODAvailable
    } = state;

    return (
        <>
            <div className='LoginMain LoginMainNoMarg' style={{width: '100%'}}>
                <div className='LoginHeader'>Cash on Delivery</div>

                <div className='BecomeLenderFlex'>
                    <div className='LoginHeader' style={{width: 'auto'}}>
                        Available
                    </div>
                    <div className='LenderSwitchInfoFlex LoginHeader'>
                        <LBSSwitch
                            onClick={() => {
                                dispatch({
                                    type: 'setIsEditCODAvailable',
                                    data: !isEditCODAvailable,
                                });
                            }}
                            disabled={isEditItemFree}
                            isChecked={isEditCODAvailable}
                            text='Yes'
                        />
                    </div>
                </div>
            </div>
            <div className='LoginMain LoginMainNoMarg' style={{width: '100%'}}>
                <div className='LoginHeader'>Item Price</div>

                <div className='BecomeLenderFlex'>
                    <div className='LoginHeader' style={{width: 'auto'}}>
                        Post item as free
                    </div>
                    <div className='LenderSwitchInfoFlex'>
                        <LBSSwitch
                            onClick={() => {
                                if (!isEditItemFree) {
                                    dispatch({
                                        type: 'setEditItemPrice',
                                        data: 0,
                                    });

                                    dispatch({
                                        type: 'setIsEditCODAvailable',
                                        data: false,
                                    });

                                    dispatch({
                                        type: 'setIsEditItemDiscount',
                                        data: false,
                                    });

                                    dispatch({
                                        type: 'setEditItemDiscount',
                                        data: 0,
                                    });
                                }
                                dispatch({
                                    type: 'setIsEditItemFree',
                                    data: !isEditItemFree,
                                });
                                setErrors({});
                            }}
                            isChecked={isEditItemFree}
                            text='Yes'
                        />
                    </div>
                </div>

                {!isEditItemFree && (
                    <>
                        <div className='LoginText'>
                            Select a paid per option and price based on what you
                            want from this item.
                        </div>

                        <ValidationTextInput
                            label='Price ($)'
                            value={editItemPrice}
                            onChange={e => {
                                dispatch({
                                    type: 'setEditItemPrice',
                                    data: e.target.valueAsNumber,
                                });
                                setErrors({});
                            }}
                            inputType='number'
                            errorMessage={Errors.price}
                        />

                        <div className='BecomeLenderFlex'>
                            <div
                                className='LoginHeader'
                                style={{width: 'auto'}}
                            >
                                Off Peak Discount
                            </div>
                            <div className='LenderSwitchInfoFlex'>
                                <LBSSwitch
                                    onClick={() =>
                                        dispatch({
                                            type: 'setIsEditItemDiscount',
                                            data: !isEditItemDiscount,
                                        })
                                    }
                                    isChecked={isEditItemDiscount}
                                    text='Yes'
                                />
                            </div>
                        </div>
                        <Fade
                            in={isEditItemDiscount}
                            timeout={300}
                            mountOnEnter
                            unmountOnExit
                        >
                            <div>
                                <div className='LoginText'>
                                    Allow borrowers to receive an off peak time
                                    discount to incentivise mid week trading
                                </div>
                                <ValidationTextInput
                                    onChange={e =>
                                        dispatch({
                                            type: 'setEditItemDiscount',
                                            data: e.target.value,
                                        })
                                    }
                                    inputType='number'
                                    value={editItemDiscount}
                                />
                                <div
                                    className='LoginText'
                                    style={{marginTop: '3%', flex: 3}}
                                >
                                    Off Peak Discount Rate
                                </div>
                            </div>
                        </Fade>
                    </>
                )}
            </div>
        </>
    );
}
