import {useContext, useEffect, useState} from 'react';
import {ReactComponent as Logo} from '../../../assets/Logos/LogoRed.svg';
import LBSSwitch from '../../../components/LBSSwitch/LBSSwitch';
import {Fade} from '@material-ui/core';
import Button from '../../../components/Button/Button';
import ValidationTextInput from '../../../components/FormComponents/ValidationTextInput';
// import {DELIVERY_OPTIONS} from '../../../assets/Data/LBSSelectOptions';
// import LBSSelectBox from '../../../components/LBSSelectBox/LBSSelectBox';
import {POST_ITEM_PAGE} from '../../../assets/Data/LBSEnum';
import {validate} from 'validate.js';
import {discountConstraints} from '../../../util/validationConstraints';

export default function AdvancedDetails({context}) {
    const [errorMessages, setErrorMessages] = useState({});
    const {state, dispatch} = useContext(context);
    const {
        postItemDescription,
        postItemPrice,
        postItemDiscount,
        isItemFree,
        isCODAvailable,
        // postItemDeliveryPrice,
        // postItemPickupPrice,
        // postItemDeliveryOption,
        isPostItemDiscount,
    } = state;

    useEffect(() => {
        if (Object.keys(errorMessages).length > 0) {
            const valid = validateInputs();
            if (valid) {
                setErrorMessages({});
                return;
            }
        }
    }, [
        postItemDescription,
        postItemPrice,
        postItemDiscount,
        // postItemDeliveryPrice,
        // postItemPickupPrice,
        // postItemDeliveryOption,
    ]);

    const getErrorMessage = inputName => {
        if (Object.keys(errorMessages).length === 0) return null;
        for (const key in errorMessages) {
            if (Object.keys(errorMessages)[0] === inputName)
                return errorMessages[key][0];
        }
    };

    const validateInputs = () => {
        if( isPostItemDiscount){
            const validationErrors = validate(
                {postItemDiscount},
                discountConstraints,
            );
            if (validationErrors) {
                setErrorMessages(validationErrors);
                return false;
            }
        }
        setErrorMessages({});
        return true;
    };

    // const disabledDeliveryOption = () => {
    //     if (!postItemDeliveryOption) return true;
    //     if (postItemDeliveryOption === 'BOTH') {
    //         if (!postItemDeliveryPrice || !postItemPickupPrice) return true;
    //     }
    //     if (postItemDeliveryOption === 'DELIVERY') {
    //         if (!postItemDeliveryPrice) return true;
    //     }
    //     if (postItemDeliveryOption === 'PICKUP') {
    //         if (!postItemPickupPrice) return true;
    //     }
    //     return false;
    // };

    return (
        <div className='RegistrationWrapper'>
            <div className='LoginMain'>
                <Logo
                    height='50px'
                    width='50px'
                    style={{marginBottom: '.5em'}}
                />

                <div className='LoginHeader'>Advanced Details</div>
                <div className='LoginText' style={{padding: 0}}>
                    <p>
                        These details are needed to tell Little Big Shed
                        borrowers finer details on items.
                    </p>
                    <p>
                        Please include as much detail as you can when filling
                        this out as borrowers will be more likely to borrow from
                        you if there is a great amount of detail.
                    </p>
                </div>
            </div>
            <div className='LoginMain LoginMainNoMarg'>
                <div className='LoginHeader'>Item Description</div>
                <div className='LoginText'>
                    Be as in-depth and detail orientated as you can be, users
                    like reading great description on products.
                </div>

                <div className='LoginHeader'>Description</div>
                <textarea
                    rows='10'
                    maxLength='254'
                    value={postItemDescription}
                    placeholder='Describe your item!'
                    className='LoginInput PostItem__TextArea'
                    onChange={e =>
                        dispatch({
                            type: 'setPostItemDescription',
                            data: e.target.value,
                        })
                    }
                />
            </div>

            <div className='LoginMain LoginMainNoMarg'>
                <div className='LoginHeader'>Cash on Delivery</div>

                <div className='BecomeLenderFlex'>
                    <div className='LoginHeader' style={{width: 'auto'}}>
                    Available
                    </div>
                    <div className='LenderSwitchInfoFlex LoginHeader'>
                        <LBSSwitch
                            onClick={() => {
                                dispatch({
                                    type: 'setIsCODAvailable',
                                    data: !isCODAvailable,
                                });

                            }}
                            disabled={isItemFree}
                            isChecked={isCODAvailable}
                            text='Yes'
                        />
                    </div>
                </div>

                </div>
            <div className='LoginMain LoginMainNoMarg'>
                <div className='LoginHeader'>Charge out cost</div>

                <div className='BecomeLenderFlex'>
                    <div className='LoginHeader' style={{width: 'auto'}}>
                    Post item as free
                    </div>
                    <div className='LenderSwitchInfoFlex'>
                        <LBSSwitch
                            onClick={() => {
                                if(!isItemFree){
                                    dispatch({
                                        type: 'setPostItemPrice',
                                        data: 0,
                                    })
                                    dispatch({
                                        type: 'setIsCODAvailable',
                                        data: false,
                                    })
                                    dispatch({
                                        type: 'setIsPostItemDiscount',
                                        data: false,
                                    });

                                    dispatch({
                                        type: 'setPostItemDiscount',
                                        data: 0,
                                    })
                                }
                                dispatch({
                                    type: 'setIsItemFree',
                                    data: !isItemFree,
                                });
                            }}
                            isChecked={isItemFree}
                            text='Yes'
                        />
                    </div>
                </div>
              {! isItemFree && 
              <>
              <div className='LoginText'>
                    Define a price you would like your item to be charged out at
                    on a per slot basis. Slots are 4 hours long.
                </div>

                <ValidationTextInput
                    label='Price ($)'
                    onChange={e =>
                        dispatch({
                            type: 'setPostItemPrice',
                            data: parseInt(e.target.value) || '',
                        })
                    }
                    inputType='number'
                    placeholder='Enter price'
                    value={postItemPrice}
                />
                <div className='LoginText'>
                    <b>Note :</b> As a Lender, the only cost of using Little Big
                    Shed is a 2.7% processing fee + 30c transaction fee for
                    domestic cards and 3.7% processing fee + 30c transaction fee
                    for international cards, charged by Stripe, our third-party
                    payment provider. This amount is deducted from what you get
                    paid each time your item is rented out.
                </div>
              
                <div className='BecomeLenderFlex'>
                    <div className='LoginHeader' style={{width: 'auto'}}>
                        Off Peak Discount
                    </div>
                    <div className='LenderSwitchInfoFlex'>
                        <LBSSwitch
                            onClick={() => {
                                dispatch({
                                    type: 'setIsPostItemDiscount',
                                    data: !isPostItemDiscount,
                                });
                            }}
                            isChecked={isPostItemDiscount}
                            text='Yes'
                        />
                    </div>
                </div>
             
                <Fade
                    in={isPostItemDiscount}
                    timeout={300}
                    mountOnEnter
                    unmountOnExit
                >
                    <div>
                        <div className='LoginText'>
                            Define a price you would like your item to be
                            charged out at, on a per slot basis. Slots are 4
                            hours long{` (% of Price)`}.
                        </div>
                        <ValidationTextInput
                            inputType='number'
                            value={postItemDiscount.toString()}
                            onChange={e =>
                                dispatch({
                                    type: 'setPostItemDiscount',
                                    data: parseInt(e.target.value) || '',
                                })
                            }
                            placeholder='5%'
                            errorMessage={getErrorMessage('postItemDiscount')}
                        />
                    </div>
                </Fade>
                </>
                }
          <div className='post-item-next'>
          <Button
                    text='Next'
                    onClick={() => {
                        const valid = validateInputs();
                        if (!valid) return;
                        dispatch({
                            type: 'setCurrentPage',
                            data: POST_ITEM_PAGE.LOCATION,
                        });
                    }}
                    isDisabled={
                        !postItemDescription ||
                        (isItemFree ? false : !postItemPrice )
                    }
                />
          </div>
            </div>
        </div>
    );
}
