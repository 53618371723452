import React from 'react';

type Props = {
    extensionPrice: number | undefined | string;
    itemPrice: number;
};

const ExtendApplicationCosts = ({extensionPrice, itemPrice}: Props) => {
    return (
        <div>
            <div className='ItemOverviewBorrowContainer'>
                <div className='ItemOverviewItemContainer'>
                    <p>Cost for Extension</p>
                    {!itemPrice ? (
                        <p>Free</p>
                    ) : (
                        extensionPrice && <p>{`$${extensionPrice}`}</p>
                    )}
                </div>
            </div>
            <div className='ItemOverviewItemContainer'>
                <p>Total Price</p>
                {!itemPrice ? (
                        <p>Free</p>
                    ) : (
                        extensionPrice && <p>{`$${extensionPrice}`}</p>
                    )}
            </div>
        </div>
    );
};

export default ExtendApplicationCosts;
