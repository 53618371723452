import {useState, useEffect} from 'react';
import './search.css';
import PageWrapper from '../../components/pageWrapper/pageWrapper.js';
import ItemCard from '../../components/itemCard/itemCard.js';
import SearchFilterBar from '../../components/searchFilterBar/searchFilterBar.js';
import {Item, ItemCategoriesEnum} from '../../types/Item';
import {makeStyles, withStyles} from '@material-ui/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import ArrowDown from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import Instance from '../../util/axios';
// import {CircularProgress} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import {Pagination} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import NoContent from '../../components/NoContent/NoContent';
import {categories} from '../../assets/Data/MarketSelections';

type ParsedSearchParams = {
    category?: ItemCategoriesEnum;
    distance?: string;
    keyword: string;
    maxPrice?: string;
    minPrice?: string;
    rating?: string;
    delivery?: '1' | '0';
    location?: string;
    lat?: string;
    lng?: string;
    cod?: boolean;
};

const paginationTheme = createTheme({
    components: {
        MuiPagination: {
            styleOverrides: {
                root: {
                    '& .MuiPaginationItem-page.Mui-selected': {
                        backgroundColor: '#c10e43',
                        color: '#fff',
                    },
                    '& .MuiPaginationItem-page.Mui-selected:hover': {
                        backgroundColor: '#c10e43',
                        color: '#fff',
                        boxShadow: '1px 2px 5px 1px #7c7272',
                    },
                },
            },
        },
    },
});

export default function Search() {
    const location = useLocation();
    const [searchItems, setSearchItems] = useState<Item[]>([]);
    const [searchItemCount, setSearchItemCount] = useState(0);
    // const [priceAscending, setPriceAscending] = useState<Item[]>([]);
    // const [priceDescending, setPriceDescending] = useState<Item[]>([]);
    // const [ratingAscending, setRatingAscending] = useState<Item[]>([]);
    // const [ratingDescending, setRatingDescending] = useState<Item[]>([]);
    // const [offset, setOffset] = useState(0);
    const [limit] = useState(8);
    // const [topPicksOffset, setTopPicksOffset] = useState(0);
    // const [loading, setLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const [topPickLoading, setTopPicksLoading] = useState(false);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [suggestedItems, setSuggestedItems] = useState<Item[]>([]);
    const [searchPage, setSearchPage] = useState(1);
    const [topPicksCurrentPage, setTopPicksCurrentPage] = useState(1);
    const [topPicksCount, setTopPicksCount] = useState(0);
    const [sortBy, setSortBy] = useState('Nothing Selected');
    const [sortOrder, setSortOrder] = useState('');
    const [sortOrderBy, setSortOrderBy] = useState('');
    const numSearchPages = Math.ceil(searchItemCount / 8);

    const queryString = require('query-string');
    const parsedSearchParams: ParsedSearchParams = queryString.parse(
        location.search,
    );
    const history = useHistory();

    useEffect(() => {
        getSearchItems(0, 1);
        getTopPicksItems(0, 1);
    }, [location.search]);

    useEffect(() => {
        getSearchItems(0, 1);
    }, [sortOrder, sortOrderBy]);

    useEffect(() => {
        window.history.scrollRestoration = 'manual';
    }, []);

    const getSearchItems = async (offset: number, page: number) => {
        setSearchLoading(true);
        // setOffset(offset);
        setSearchPage(page);
        var searchString = `items/search${formatSearchParams().concat(
            `&offset=${offset}&limit=${limit}`,
        )}`;
        if (sortOrder && sortOrderBy) {
            searchString += `&order=${sortOrder}&orderBy=${sortOrderBy}`;
        }
        try {
            const {data: searchData} = await Instance.get(searchString);
            setSearchItemCount(searchData.count);
            if (searchData.count > 0) {
                setSearchItems(searchData.data);
                // setPriceAscending(searchData.data);
                // setPriceDescending(searchData.data);
            } else {
                setSearchItems([]);
                // setPriceAscending([]);
                // setPriceDescending([]);
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // console.log(error.response)
            }
        } finally {
            setSearchLoading(false);
        }
    };

    const getTopPicksItems = async (offset: number, page: number) => {
        setTopPicksLoading(true);
        // setTopPicksOffset(offset);
        setTopPicksCurrentPage(page);
        try {
            const {data: topBooking} = await Instance.get(
                `items/top-picks${formatSearchParams().concat(
                    `&offset=${offset}&limit=${limit}`,
                )}`,
            );
            setTopPicksCount(topBooking.count);
            if (topBooking.count) setSuggestedItems(topBooking.data);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // console.log(error.response)
            }
        } finally {
            setTopPicksLoading(false);
        }
    };

    const formatSearchParams = () => {
        let searchQuery = '';
        const {
            keyword,
            category,
            delivery,
            location,
            maxPrice,
            minPrice,
            rating,
            distance,
            lat,
            lng,
            cod,
        } = parsedSearchParams;
        let searchParamsArray = Object.keys(parsedSearchParams);

        if (searchParamsArray.length > 0) {
            setIsFilterActive(true);
            if (
                searchParamsArray.length === 1 &&
                searchParamsArray[0] === 'keyword' &&
                !parsedSearchParams['keyword'] &&
                !sortOrder &&
                !sortOrderBy
            ) {
                setIsFilterActive(false);
            }
        } else {
            setIsFilterActive(false);
        }
        if (keyword) {
            searchQuery = searchQuery.concat('?keyword=' + keyword);
        } else {
            searchQuery = searchQuery.concat('?keyword=');
        }
        if (category) {
            const catArr = category?.split(',');
            searchQuery += `&category=`;
            catArr?.map((dt, index) => {
                switch (String(dt.trim())) {
                    case 'DIYGarden':
                        searchQuery += `${encodeURIComponent('DIY & Garden')}`;
                        break;
                    case 'BabiesKids':
                        searchQuery += `${encodeURIComponent('Babies & Kids')}`;
                        break;
                    case 'GamingToys':
                        searchQuery += `${encodeURIComponent('Gaming & Toys')}`;
                        break;
                    case 'PartiesEvents':
                        searchQuery += `${encodeURIComponent(
                            'Parties & Events',
                        )}`;
                        break;
                    case 'OutdoorSport':
                        searchQuery += `${encodeURIComponent(
                            'Outdoor & Sport',
                        )}`;
                        break;
                    default:
                        searchQuery += `${encodeURIComponent(dt)}`;
                }
                if (index !== catArr.length - 1) {
                    searchQuery += ',';
                }
            });
            // switch (String(category.trim())) {
            //   case 'DIY':
            //     searchQuery += `&category=${encodeURIComponent('DIY & Garden')}`
            //     break
            //   case 'Babies':
            //     searchQuery += `&category=${encodeURIComponent('Babies & Kids')}`
            //     break
            //   case 'Gaming':
            //     searchQuery += `&category=${encodeURIComponent('Gaming & Toys')}`
            //     break
            //   case 'Parties':
            //     searchQuery += `&category=${encodeURIComponent('Parties & Events')}`
            //     break
            //   case 'Outdoor':
            //     searchQuery += `&category=${encodeURIComponent('Outdoor & Sport')}`
            //     break
            //   default:
            //     searchQuery += `&category=${encodeURIComponent(category)}`
            // }
        }
        if (rating) {
            searchQuery = searchQuery.concat('&rating=' + rating);
        }
        if (location) {
            searchQuery = searchQuery.concat('&location=' + location);
        }
        if (minPrice)
            searchQuery = searchQuery.concat('&minPrice=' + minPrice);
        if (Number(maxPrice) !== 100000000 && maxPrice)
            searchQuery = searchQuery.concat('&maxPrice=' + maxPrice);
        if (distance !== '0' && distance !== '1' && distance && lng && lat) {
            searchQuery = searchQuery.concat(
                '&distance=' + distance + `&lat=${lat}&lng=${lng}`,
            );
        }
        if(cod){
            searchQuery = searchQuery.concat('&cod=true');
        }
        if (delivery === '1') {
            searchQuery = searchQuery.concat('&delivery=1');
        } else if (delivery === '0') {
            searchQuery = searchQuery.concat('&delivery=0');
        } else {
            searchQuery = searchQuery;
        }

        // searchQuery = searchQuery.concat(`&offset=${offset}&limit=${limit}`)
        return searchQuery;
    };

    const getSearchResultsPage = () => {
        let content: any = [];
        if (searchItemCount > 0) {
            for (let i = 0; i < searchItems.length; i++) {
                content.push(
                    <ItemCard
                        favourited={false}
                        item={searchItems[i]}
                        key={i}
                    />,
                );
            }
        } else {
            const {category} = parsedSearchParams;
            let categoryFind = '';

            if (category) {
                const catArr = category?.split(',');
                if (catArr) {
                    categoryFind = categories
                        .filter(cat => catArr.includes(cat.categorySearchParam)) // Filter categories that match the search param
                        .map(cat => cat.category) // Map to category names
                        .join(', '); // Join them with a comma separator
                }
            }

            content.push(
                <div className='no-result-found'>
                    {' '}
                    <NoContent
                        header=''
                        text={
                            categoryFind
                                ? `Be the first one to list on ${categoryFind} page and earn!`
                                : 'No results found, try searching with different options.'
                        }
                        buttonText=''
                        onButtonClick={() => history.push('/search')}
                    />
                </div>,
            );
        }

        return content;
    };

    const skeleton = (itemNumber: number) => {
        let content = [];
        for (let i = 0; i < itemNumber; i++) {
            content.push(
                <div className='ItemCard' key={i}>
                    <Skeleton
                        className='PreviewImage '
                        variant='rectangular'
                        width={'100%'}
                        height={200}
                    />
                    <div
                        style={{padding: '10px 0px 0px 0px'}}
                        className='ItemDetailsContainer'
                    >
                        <Skeleton width='100%' height={50} />
                        <Skeleton width='60%' height={40} />
                        <Skeleton width='70%' height={30} />
                        <Skeleton width='100%' height={30} />
                    </div>
                </div>,
            );
        }
        return content;
    };

    const handlePagination = (pageNumber: number) => {
        if (searchPage !== pageNumber) {
            getSearchItems((pageNumber - 1) * limit, pageNumber);
        }
    };
    const handleOffPickPagination = (pageNumber: number) => {
        if (topPicksCurrentPage !== pageNumber) {
            getTopPicksItems((pageNumber - 1) * limit, pageNumber);
        }
    };
    const getSearchResultsMain = () => {
        return (
            <div>
                <div className='SearchItemCardSection'>
                    {searchLoading ? skeleton(8) : getSearchResultsPage()}
                </div>
            </div>
        );
    };

    const randomItemsMapper = () => {
        let content: any = [];
        if (topPicksCount > 0) {
            for (let i = 0; i < suggestedItems.length; i++) {
                content.push(
                    <ItemCard
                        favourited={false}
                        item={suggestedItems[i]}
                        key={i}
                    />,
                );
            }
        } else {
            const {category} = parsedSearchParams;
            let categoryFind = '';

            if (category) {
                const catArr = category?.split(',');
                if (catArr) {
                    categoryFind = categories
                        .filter(cat => catArr.includes(cat.categorySearchParam)) // Filter categories that match the search param
                        .map(cat => cat.category) // Map to category names
                        .join(', '); // Join them with a comma separator
                }
            }
            content.push(
                <div className='no-result-found'>
                    {' '}
                    <NoContent
                        header=''
                        text={
                            categoryFind
                                ? `Be the first one to list on ${categoryFind} page and earn!`
                                : 'No results found, try searching with different options.'
                        }
                        buttonText=''
                        onButtonClick={() => history.push('/search')}
                    />
                </div>,
            );
        }
        return content;
    };

    const handleChange = (event: any) => {
        const selectedValue = event.target.value;
        setSortBy(selectedValue);
        setIsFilterActive(true);
        if (selectedValue === 'Rating - Low to High') {
            setSortOrderBy('rating');
            setSortOrder('ASC');
        } else if (selectedValue === 'Rating - High to Low') {
            setSortOrderBy('rating');
            setSortOrder('DESC');
        } else if (selectedValue === 'Price - Low to High') {
            setSortOrderBy('price');
            setSortOrder('ASC');
        } else if (selectedValue === 'Price - High to Low') {
            setSortOrderBy('price');
            setSortOrder('DESC');
        } else {
        }
    };
    const classes = useStyles();

    return (
        <PageWrapper>
            <SearchFilterBar
                keyWord={parsedSearchParams.keyword}
                address={{
                    lat: parsedSearchParams.lat,
                    lng: parsedSearchParams.lng,
                }}
            />
            {/* {loading ? (
        <div className='ItemPage__Loading__Container'>
          <CircularProgress size={75} color={'secondary'} />
        </div>
      ) : ( */}
            <div className='container SearchMainContainer'>
                <div className='SearchSortFlex'>
                    <div className='SearchMainText'>
                        {parsedSearchParams.keyword && (
                            <>
                                <span>Search results for: </span>
                                <span style={{fontWeight: 'normal'}}>
                                    {parsedSearchParams.keyword}
                                </span>
                            </>
                        )}
                    </div>
                    <div className='flex items-center gap-5'>
                        <div className='SearchMainText'>
                            Sort by:
                            <Select
                                onChange={handleChange}
                                input={<BootstrapInput />}
                                className={`${classes.select}`}
                                IconComponent={ArrowDown}
                                value={sortBy}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 250,
                                        horizontal: -50,
                                    },
                                    transformOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    classes: {
                                        paper: classes.dropDown,
                                    },
                                }}
                            >
                                <MenuItem
                                    value='Nothing Selected'
                                    style={{
                                        display: 'none',
                                    }}
                                >
                                    {sortBy}
                                </MenuItem>
                                <MenuItem
                                    value='Price - Low to High'
                                    // onClick={() => {
                                    //   sortPriceLowToHigh()
                                    // }}
                                >
                                    Price - Low to High
                                </MenuItem>
                                <MenuItem
                                    value='Price - High to Low'
                                    // onClick={() => sortPriceHighToLow()}
                                >
                                    Price - High to Low
                                </MenuItem>
                                <MenuItem
                                    value='Rating - Low to High'
                                    // onClick={() => sortRatingLowToHigh()}
                                >
                                    Rating - Low to High
                                </MenuItem>
                                <MenuItem
                                    value='Rating - High to Low'
                                    // onClick={() => sortRatingHighToLow()}
                                >
                                    Rating - High to Low
                                </MenuItem>
                            </Select>
                        </div>
                        {isFilterActive && (
                            <div className='clear-button-Flex'>
                                <button
                                    onClick={() => {
                                        setIsFilterActive(false);
                                        setSortOrder('');
                                        setSortOrderBy('');
                                        setSortBy('Nothing Selected');
                                        history.push(`/search/`);
                                    }}
                                    className='clear-filter-button'
                                >
                                    Clear Filters <ClearIcon />
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                {/* {searchItems.length > 0 ? ( */}
                {getSearchResultsMain()}
                {/* ) : (
            <div>No results found, try searching with different options.</div>
          )} */}
                <div className='PaginationSection'>
                    {searchItemCount > 0 && (
                        <ThemeProvider theme={paginationTheme}>
                            <Pagination
                                defaultPage={1}
                                page={searchPage}
                                onChange={(e, page) => handlePagination(page)}
                                count={numSearchPages}
                                size='large'
                                // color='primary'
                            />
                        </ThemeProvider>
                    )}
                </div>
                <div className='SuggestedItemsSection'>
                    <div className='SearchSortFlex'>
                        <div className='SearchMainText'>
                            Suggested items outside your search for:{' '}
                            <span style={{fontWeight: 'normal'}}>
                                {parsedSearchParams.keyword}
                            </span>
                        </div>
                    </div>
                    <div className='ItemCardSection'>
                        {/* Need the similar items backend for this section, placeholder first item from search for now */}

                        {topPickLoading ? skeleton(8) : randomItemsMapper()}
                    </div>
                    <div className='PaginationSection'>
                        {topPicksCount > 0 && (
                            <ThemeProvider theme={paginationTheme}>
                                <Pagination
                                    defaultPage={1}
                                    page={topPicksCurrentPage}
                                    onChange={(e, page) =>
                                        handleOffPickPagination(page)
                                    }
                                    count={numSearchPages}
                                    // color='primary'
                                    size='large'
                                />
                            </ThemeProvider>
                        )}
                    </div>
                </div>
            </div>
            {/* )} */}
        </PageWrapper>
    );
}

const BootstrapInput = withStyles(theme => ({
    input: {
        display: 'inline',
        paddingLeft: '.3em',
        textAlign: 'left',
        fontSize: '20px',
        fontFamily: ['DMSans, sans-serif'].join(','),
        '&:focus': {
            backgroundColor: '#FFFFFF',
            borderRadius: '15px',
        },
    },
}))(InputBase);

const useStyles = makeStyles({
    dropDown: {
        border: '2px solid #95272f',
        padding: '1em',
        borderRadius: '15px',
        boxSizing: 'content-box',
        '& .MuiMenuItem-root': {
            fontFamily: 'DMSans, sans-serif',
        },
    },
    select: {
        fontWeight: 'normal',
        '& .MuiSvgIcon-root': {
            color: '#95272f',
            // marginRight: '6px',
        },
    },
});
