import {Item} from '../../types/Item';
import './TradeSidebar.css';
import BookingCalculator from '../../util/calculator/BookingCalculator';
import {BookingDuration} from '../../types/Booking';
import moment from 'moment';
import {useState, useEffect} from 'react';
import {Booking} from '../../types/Booking';
import {itemCostsCalCulation} from '../../util/calculator/calculations';
import {calculateOffPeakDiscount} from '../../util/calculator/calculations';

type Props = {
    bookingPriceCalculator: BookingCalculator;
    selectedBookingDuration: BookingDuration;
    isDeliverySelected: boolean;
    isPickupSelected: boolean;
    item: Item;
    bookingDurations: BookingDuration[];
    selectedBooking: Booking;
};

const TradeSidebarExtendedPaymentPanel = ({
    bookingPriceCalculator,
    isDeliverySelected,
    isPickupSelected,
    item,
    selectedBookingDuration,
    bookingDurations,
    selectedBooking,
}: Props) => {
    const [durations, setDurations] = useState<BookingDuration[]>([]);
    const { itemPrice} = selectedBookingDuration;

    const [prices, setPrices] = useState({
        total: 0,
        paidAmount: 0,
        payableAmount: 0,
    });
    //   const itemCost = bookingPriceCalculator.calculateItemCostsWithoutOptions()

    useEffect(() => {
        let reverseCopy: any = bookingDurations.slice().reverse();
        setDurations(reverseCopy);
        calculateTotalAndPaidAmount(reverseCopy);
    }, []);

    const calculateTotalAndPaidAmount = (durationData: any) => {
        let totalObj = {
            total: 0,
            paidAmount: 0,
            payableAmount: 0,
        };
        durationData.map((dt: any, index: number) => {
            if (dt.status !== 'REJECTED') {
                if (dt.status !== 'APPLIED') {
                    totalObj.paidAmount += dt.totalPrice;
                } else {
                    totalObj.payableAmount += dt.totalPrice;
                }
                totalObj.total += dt.totalPrice;
            }
        });
        setPrices(totalObj);
    };

    return (
        <div className='TradeSidebarSection'>
            <div className='TradeSidebarSubHeading'>
                <span>Itemised Costs</span>
            </div>
            {durations?.map((dt, index) => {
                if (dt?.status !== 'REJECTED') {
                    const paymentStatus =
                        dt?.status !== 'APPLIED' ? '(paid)' : '(unpaid)';
                    const isExtend = index > 0;
                    const isSameDateTime = moment(dt?.startDate).isSame(
                        dt?.endDate,
                    );
                    let modifyStartDate = new Date(dt?.startDate);
                    let modifyEndDate = new Date(dt?.endDate);
                    let modifiedPrevStartEndDate = moment(modifyStartDate);

                    if (index > 0 && !isSameDateTime) {
                        if (modifiedPrevStartEndDate.hours() === 12) {
                            modifiedPrevStartEndDate = moment(
                                modifyStartDate,
                            ).set({
                                hour: 13,
                                minute: 0,
                                second: 0,
                                millisecond: 0,
                            });
                        } else if (modifiedPrevStartEndDate.hours() === 17) {
                            modifiedPrevStartEndDate = moment(modifyStartDate)
                                .add(1, 'day')
                                .startOf('day')
                                .add(8, 'hours');
                        }
                        modifyStartDate = modifiedPrevStartEndDate.toDate();
                    }
                    // const itemCost = Number(itemCostsCalCulation(modifyStartDate, modifyEndDate, res?.itemPrice, isExtend));
                    return (
                        <div key={index}>
                            <div className='TradeSidebarStepExtendHeading'>
                                <span>
                                    {index === 0
                                        ? 'Booking Cost'
                                        : `Extended Cost  ${index}`}
                                    {selectedBooking?.isCOD ? "": paymentStatus}
                                </span>
                            </div>

                            {itemPrice ? (
                                <>
                                    <div className='TradeSidebarCostFlex'>
                                        <span>Cost for Item </span>
                                        {item && (
                                            <span className='ItemOverviewPrice'>
                                                $
                                                {itemCostsCalCulation(
                                                    modifyStartDate,
                                                    modifyEndDate,
                                                    dt.itemPrice,
                                                    isExtend,
                                                )}
                                            </span>
                                        )}
                                    </div>
                                    {/* {index === 0 && isDeliverySelected && item && (
                                <div className='TradeSidebarCostFlex'>
                                    <span>Item Delivery </span>
                                    <span className='ItemOverviewPrice'>{`$${item.deliveryPrice}`}</span>
                                </div>
                            )}
                            {index === 0 && isPickupSelected && item && (
                                <div className='TradeSidebarCostFlex'>
                                    <span>Item Pickup </span>
                                    <span className='ItemOverviewPrice'>{`$${item.pickupPrice}`}</span>
                                </div>
                            )} */}

                                    <div className='TradeSidebarCostFlex'>
                                        <span>Off Peak Discount</span>
                                        <span className='ItemOverviewPrice'>
                                            -$
                                            {calculateOffPeakDiscount(
                                                modifyStartDate,
                                                modifyEndDate,
                                                item.discount,
                                                dt.itemPrice,
                                            )}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <div className='TradeSidebarCostFlex'>
                                    <span>Cost for Item </span>
                                    {item && (
                                        <span className='ItemOverviewPrice'>
                                            Free
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                }
            })}
            <div
                className='TradeSidebarCostFlex'
                style={{
                    //   paddingTop: '1rem',
                    borderTop: '1px solid #31364c',
                    paddingBottom: 0,
                }}
            >
                {/* <span className='ItemOverviewSmallText'>Off Peak Discount</span>
        <span className='ItemOverviewSmallText'>
          -${bookingPriceCalculator?.calculateOffPeakDiscount().toFixed(2)}
        </span> */}
            </div>
            {itemPrice ? (
                <>
                    <div
                        className='TradeSidebarCostFlex'
                        style={{paddingTop: '0.5rem'}}
                    >
                        <span>Total</span>
                        <span className='ItemOverviewPrice'>
                            ${prices.total}
                        </span>
                    </div>
                   {!selectedBooking?.isCOD && <div className='TradeSidebarCostFlex'>
                        <span>Paid Amount</span>
                        <span className='ItemOverviewPrice'>
                            ${prices.paidAmount}
                        </span>
                    </div>}
                    {prices.payableAmount !== 0 &&  !selectedBooking?.isCOD && (
                        <div className='TradeSidebarCostFlex'>
                            <span>Payable Amount</span>
                            <span className='ItemOverviewPrice'>
                                ${prices.payableAmount}
                            </span>
                        </div>
                    )}
                </>
            ) : (
                <div
                    className='TradeSidebarCostFlex'
                    style={{paddingTop: '0.5rem'}}
                >
                    <span>Total</span>
                    <span className='ItemOverviewPrice'>Free</span>
                </div>
            )}
        </div>
    );
};

export default TradeSidebarExtendedPaymentPanel;
