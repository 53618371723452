import {Dialog, DialogContent} from '@material-ui/core';

export default function ItemUnavailableModel({
    isOpen,
    setIsOpen,
    handleClick,
}) {
    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className='cus-modal'
        >
            <DialogContent className='BorrowerMain'>
                <span className='ApplicationModalTitle'>Alert</span>
                <br></br>
                <span className='ApplicationModalText'>
                    Unfortunately, this item is not available for booking at the moment.
                </span>
                <div
                    className='ApplicationModalButton'
                    onClick={handleClick}
                    style={{margin: '1rem 0'}}
                >
                    <span className='ApplicationModalButtonText'>Ok</span>
                </div>
            </DialogContent>
        </Dialog>
    );
}
