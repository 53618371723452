import {Dialog, DialogContent} from '@material-ui/core';
import './PaymentMethodModal.css';

import {
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
} from '@mui/material';
import Button from '../../Button/Button';

export default function PaymentMethodModal({
    isOpen,
    setIsOpen,
    item,
    confirm,
    isCardAvailable,
    handleChange,
    paymentMethod,
}) {


    const handleConfirm = () => {
        confirm();
        setIsOpen(false);
    };

    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className='cus-modal'
        >
            <DialogContent className='BorrowerMain'>
                <span className='ApplicationModalTitle'>
                    Select Payment Method
                </span>
                <br></br>
                <div className='payment-method-card'>
                    <FormControl component='fieldset'>
                        <FormLabel className='payment-method-label'>Payment Method</FormLabel>
                        <RadioGroup
                            aria-label='payment-method'
                            value={paymentMethod}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value='card'
                                control={<Radio sx={{
                                    color: '#ac172c',
                                    '&.Mui-checked': {
                                      color: '#ac172c',  // Change this to the color you want when selected
                                    },
                                  }}/>}
                                // disabled={!isCardAvailable}
                                label='Credit Card'
                            />
                            <FormControlLabel
                                value='cod'
                                control={<Radio sx={{
                                    color: '#ac172c',
                                    '&.Mui-checked': {
                                      color: '#ac172c',  // Change this to the color you want when selected
                                    },
                                  }}/>}
                                disabled={!item.isCOD}
                                label='Cash on Delivery (COD)'
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                 <div className='button-confirm'>
                 <Button
                        onClick={handleConfirm}
                        text='Confirm'
                        isDisabled={!paymentMethod}
                    />
                 </div>
                
            </DialogContent>
        </Dialog>
    );
}
