import './footer.css';
import {Link} from 'react-router-dom';
import FooterCopyRightComonent from './footerCopyRightComonent';
import FooterDownloadComponent from './footerDownloadComponent';
import useGlobalState from '../../util/useGlobalState';

export default function Footer() {
    const {user} = useGlobalState().state;
    return (
        <div className='Footer'>
            <FooterDownloadComponent />
            <div className='FooterBar Links'>
                <div className='container'>
                    <div className='LinkWrapper'>
                        <div className='footer-cols'>
                            <div className='LinkHeader'>Account</div>
                            <div className='LinkContainer'>
                                <Link className='LinkText' to={'/contact_us'}>
                                    Support Team
                                </Link>
                                <Link className='LinkText' to={`/user/account`}>
                                    Settings
                                </Link>
                                {user?.isLender && (
                                    <>
                                        <Link
                                            className='LinkText'
                                            to={`/user/account`}
                                        >
                                            Availability
                                        </Link>
                                        <Link
                                            className='LinkText'
                                            to={'/user/your_shed'}
                                        >
                                            My Shed
                                        </Link>{' '}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='footer-cols'>
                            <div className='LinkHeader'>Conditions</div>
                            <div className='flex flex-col'>
                                <a
                                    className='LinkText'
                                    download='Little Big Shed _ Terms & Conditions.pdf'
                                    href='/Policies/LBS_Terms&Conditions.pdf'
                                >
                                    Terms and Condtions
                                </a>
                                <Link
                                    className='footer_section_title LinkText'
                                    to='/privacy_policy'
                                >
                                    Privacy Policy
                                </Link>
                                <a
                                    className='LinkText'
                                    // download='LBS _ Lender Protection Policy.pdf'
                                    // href='/Policies/LBS_LenderProtectionPolicy.pdf'
                                >
                                    Lender Agreement
                                </a>
                                <Link
                                    className='footer_section_title LinkText'
                                    to='/cancellation_policy'
                                >
                                    Cancellation and Refund Policy
                                </Link>
                            </div>
                        </div>

                        <div className='footer-cols'>
                            <div className='LinkHeader'>Discover</div>
                            <div className='flex flex-col'>
                                <Link
                                    className='LinkText'
                                    to={'/search/?keyword='}
                                >
                                    New Items
                                </Link>
                                <Link
                                    className='LinkText'
                                    to={'/search/?keyword='}
                                >
                                    Top Items
                                </Link>
                                <Link
                                    className='LinkText'
                                    to={'/search/?keyword='}
                                >
                                    Search
                                </Link>
                                <Link
                                    className='LinkText'
                                    to={'/search/?keyword='}
                                >
                                    Discover
                                </Link>
                            </div>
                        </div>

                        <div className='footer-cols'>
                            <div className='LinkHeader'>Top Categories</div>
                            <div className='LinkContainer'>
                                <Link
                                    className='LinkText'
                                    to={'/search/?keyword=&category=Vehicle'}
                                >
                                    Automative
                                </Link>
                                <Link
                                    className='LinkText'
                                    to={`/search/?keyword=&category=DIYGarden`}
                                >
                                    Gardening
                                </Link>
                                <Link
                                    className='LinkText'
                                    to={'/search/?keyword=&category=Household'}
                                >
                                    Hand Tools
                                </Link>
                                <Link
                                    className='LinkText'
                                    to={
                                        '/search/?keyword=&category=Electronics'
                                    }
                                >
                                    Power Tools
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterCopyRightComonent />
        </div>
    );
}
